<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/league')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3 text-center">
        <div class="align-items-center">
          <div class="profile-img-edit align-items-center">
            <img
              :src="body.image"
              class="profile-pic height-150 width-150 align-items-center"
              style="width: 100px;height: 100px;"
              @error="$errorHandler"
            />
            <div class="p-image">
              <div class="position-relative">
                <i class="ri-pencil-line upload-button"></i>
                <b-form-file
                  class="h-100 position-absolute"
                  accept="image/*"
                  style="opacity: 0;"
                  @change="previewImage"
                ></b-form-file>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="name" class="form-label">
          الاسم
        </label>
        <input class="form-control" id="name" v-model="body.name" />
      </div>

      <div class="mb-3">
        <label for="location" class="form-label">
          المكان
        </label>
        <input class="form-control" id="location" v-model="body.location" />
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
        />
        <Button
          v-else
          label="اضافه جديد"
          icon="pi pi-plus"
          :disabled="disabledAdd"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        location: null,
        image: '',
      },
      id: null,
      disabledAdd: false,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },

    save() {
      if (this.body.name && this.body.image && this.body.location) {
        this.disabledAdd = true;
        this.$http.post(`league`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/league');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.name && this.body.image && this.body.location) {
        this.disabledAdd = true;
        this.$http.put(`league/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/league');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`league/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.image = this.$baseUploadURL + res.data.image;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style>
.p-image {
  width: 0;
}
.p-image .custom-file-input {
  text-align: center;
  width: 89vw;
  position: absolute;
  top: -99px;
  height: 105px;
}
</style>
